import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import RenderRichText from "../../utils/contentful-rich-text"
import { Gmap } from "../components/gmap"


export const newsQuery = graphql`
  query($slug: String) {
    Current: contentfulNews(slug: {eq: $slug}) {          
      id
      cover {
        gatsbyImageData(height: 500, placeholder: BLURRED, formats: [AUTO, WEBP])
      }
      title
      slug
      body {
        raw
      }
      createdAt(formatString: "DD.MM.YYYY")
      file {
        title
        description
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
        localFile {
          url
          absolutePath
          publicURL          
        }
      }
    }

    Rest: allContentfulNews(
      sort: {fields: createdAt, order: DESC}
      filter: {slug: {ne: $slug}}
      limit: 2
      ) {
      nodes {
        id
        cover {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
        }
        title
        slug
        body {
          raw
        }
        createdAt(formatString: "DD.MM.YYYY")
        file {
          title
          description
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
          localFile {
            url
            absolutePath
            publicURL            
          }
        }
      }
    }    
  }
`;


export default function BlogPost({ data }) {
  const news = data.Current
  const rest = data.Rest
  const image = getImage(news.cover)

  return (
    <>
      <section className="px-4 text-justify md:mx-auto md:max-w-7xl">
        <div className="container flex flex-wrap py-6 mx-auto">
          <section className="flex flex-col items-center w-full px-3 md:w-2/3">
            <article className="flex flex-col my-4 shadow">
              <GatsbyImage image={image} alt={news.title} />
              <div className="flex flex-col justify-start p-6 bg-white">
                <h1 className="pb-2 text-3xl font-bold hover:text-gray-700">{news.title}</h1>
                <p className="inline-flex items-center pb-6 space-x-2 text-gray-500">
                  <svg xmlns="http://www.w3.org/2000/svg" className="inline-block w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                  </svg>
                  <span className="text-sm font-semibold">{news.createdAt}</span>
                </p>
                {RenderRichText(news.body)}
                {news.file && news.file[0].localFile.publicURL && <p className="pt-6"><a className="font-semibold text-red-500 " href={news.file[0].localFile.url} dowload>{news.file[0].title}</a></p>}
              </div>
            </article>
            <div className="flex w-full pt-6">
              <div className="w-1/2 p-6 text-left bg-white shadow hover:shadow-md">
                <p className="flex items-center text-base font-bold text-blue-800">
                  <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 17l-5-5m0 0l5-5m-5 5h12" />
                  </svg>
                  <Link to={`/news/${rest.nodes[1].slug}`}>News Précédente</Link>
                </p>
                <p className="pt-2 text-sm"><Link to={`/news/${rest.nodes[1].slug}`}>{rest.nodes[1].title}</Link></p>
              </div>
              <div className="w-1/2 p-6 text-right bg-white shadow hover:shadow-md">
                <p className="flex items-center justify-end text-base font-bold text-blue-800">
                  <Link to={`/news/${rest.nodes[0].slug}`}>News Suivante</Link>
                  <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7l5 5m0 0l-5 5m5-5H6" />
                  </svg>
                </p>
                <p className="pt-2 text-sm"><Link to={`/news/${rest.nodes[0].slug}`}>{rest.nodes[0].title}</Link></p>
              </div>
            </div>
          </section>

          <aside className="flex flex-col items-center w-full px-3 md:w-1/3">
            <div className="flex flex-col w-full p-6 my-4 bg-white shadow">
              <p className="pb-5 text-xl font-semibold">A propos</p>
              <p className="pb-2">Découvrez les joies de l'équitation, grâce à l'expérience et la complémentarité de notre équipe, composée d'enseignants diplômés et de professionnels qualifiés.</p>
            </div>
          </aside>
        </div>
      </section>
      <Gmap />
    </>
  )
}